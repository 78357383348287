<template>
  <Login />
</template>
<script>
import Login from "@/containers/Login/Login.vue";
import { defineComponent } from "vue";
export default defineComponent({
  setup() {},
  components: {
    Login,
  },
});
</script>
