<template>
  <Login />
</template>

<script>
import Login from "@/components/Login/Login";
export default {
  name: "LoginContainer",
  components: {
    Login,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style module src="./style.css"></style>
